import { JobSearchStandaloneWidget } from "@sourceflow-uk/job-search";
import "@sourceflow-uk/job-search/dist/bundle.css";
import cn from "classnames";
import { Col, Container, Row } from "react-bootstrap";

import classes from "./styles.module.scss";

export default function ({
  className = "py-5 py-md-6",
  title = "Find Your Perfect Fit",
  description = "<p>Don't miss out on your next life sciences opportunity. Search now.</p>",
  EditableText,
  EditableHtml,
  theme = "light",
}) {
  return (
    <div className={cn(className, classes.JobSearchForm)} data-bs-theme={theme}>
      <Container fluid="md">
        <Row>
          <Col xs={12} className="border-bottom mb-3">
            <EditableText path="title" tag="h2">
              {title}
            </EditableText>
          </Col>
          <Col xs={12} md={4}>
            <EditableHtml path="description">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </EditableHtml>
          </Col>
          <Col xs={12} md={8}>
            <JobSearchStandaloneWidget
              translations={{
                "input.placeHolder": "Search for jobs",
                "input.locationPlaceholder": "Location",
                "button.text": "Search Jobs",
                "input.radius.milesUnit": "miles",
                "input.radius.kilometresUnit": "km",
              }}
              searchInput={{
                queryInput: true,
                locationInput: true,
                radiusInput: false,
                radiusOptions: {
                  units: "miles",
                  values: [5, 10, 20, 30, 40, 50],
                  default: 5,
                },
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
